import {showMessageToUser} from './modal.js'
import {getCookie} from "./utilities";
// FORMS
const downloadForm = document.querySelector('#downloadForm');
const contactFormLtd = document.querySelector('#contactFormLtd');
const phoneForm = document.querySelector('#contactFormPhone');
const downloadExcelForm = document.querySelector('#downloadExcelForm');
const contactForm = document.querySelector('#contactForm');

// FORM HEADERS
const downloadHeader = document.querySelector('#myModal #downloadFormBody > p:first-of-type');
const registerHeader = document.querySelector('#register-ltd-modal #downloadFormBody > p:first-of-type');
const phoneFooter = document.querySelector('#contactFormPhone #sendMessage');
const downloadAppHeader = document.querySelector('#downloadApp #downloadFormBody > p:first-of-type');
const contactFooter = document.querySelector('#contact #msgSubmit');

// FORM SUBMIT BUTTONS
const submitDownloadBtn = document.querySelector('.btn-download');
const submitRegisterBtn = document.querySelector('.btn-register');
const submitPhoneBtn = document.querySelector('#contactFormPhone button');
const submitDownloadAppBtn = document.querySelector('.btn-download-app');
const submitFormBtn = document.querySelector('#contactForm button[name="submit"]');

// FORM CELLS
// download
const myModalEmail = document.querySelector('#myModal input[type="email"]');
// register
const registerEmail = document.querySelector('#register-ltd-modal input[type="email"]');
const registerPhone = document.querySelector('#register-ltd-modal input[type="phone"]');
// phone
const phoneEmail = document.querySelector('#contactFormPhone input[type="email"]');
const phonePhone = document.querySelector('#contactFormPhone input[type="phone"]');
// download app X KALKULATOR
const downloadAppEmail = document.querySelector('#downloadApp input[type="email"]');
// contact form
const contactFirstName = document.querySelector('#fname-contact');
const contactLastName = document.querySelector('#lname-contact');
const contactEmail = document.querySelector('#email-contact');
const contactPhone = document.querySelector('#phone-contact');
const contactMessage = document.querySelector('#message-contact');

// SEND FORM
const sendForm = async (url, type, email, phone, firstName, lastName, message) => {
    let urlSeachParams = new URLSearchParams();
    urlSeachParams.append('type', type);
    urlSeachParams.append('email', email);
    urlSeachParams.append('phone', phone);
    urlSeachParams.append('fname', firstName);
    urlSeachParams.append('lname', lastName);
    urlSeachParams.append('message', message);
    urlSeachParams.append('source', getCookie('source'));
    //TEST2
    const response = await fetch( url,
        {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: urlSeachParams,
        }
    );
    return response;
};
const baseUrl = getApiBaseUrl();
function getApiBaseUrl() {
    const url = window.location.href;
    if (url.includes('localhost')) {
        return 'https://localhost';
    } else if (url.includes('test.taxshield') || url.includes('www.apiv2.taxshield.pl')) {
        return 'https://www.apiv2.taxshield.pl';
    } else {
        return 'https://apiv2.taxshield.pl';
    }
}
const createUserAccount = async (email) => {
    return fetch(baseUrl + "/app/user", {
        "headers": {
            "accept": "*/*",
            "content-type": "application/json",
        },
        "body": '{ "login":"' + email + '" }',
        "method": "POST",
        "mode": "cors"
    });
}
// SEND DOWNLOAD FORM
const sendDownloadForm = async () => {
    const email = myModalEmail.value;
    const url = baseUrl + '/mail/download';
    const type = 'download';
    if (!downloadForm.checkValidity()) {
        downloadForm.reportValidity();
        return;
    }

    submitDownloadBtn.disabled = true;
    showMessageToUser(downloadHeader, "Wysyłam...", "#007bff");

    const response = await createUserAccount(email);

    if (response.status === 200) {
        gtag('event', 'conversion', {'send_to': 'AW-739480194/XEx5CJ340oQDEIKlzuAC'});
        showMessageToUser(downloadHeader, "Link do aplikacji został wysłany na podany adres e-mail.", "#28a745");
    } else {
        showMessageToUser(downloadHeader, "Wiadomość nie została wysłana - spróbuj ponownie później.", "#dc3545");
        throw new Error('an error has taken place');
    }
};
// SEND REGISTER FORM
const sendRegisterForm = async () => {
    const email = registerEmail.value;
    const phone = registerPhone.value;
    const url = '/mail/form';
    const type = 'contact';
    if (!contactFormLtd.checkValidity()) {
        contactFormLtd.reportValidity();
        return;
    }

    submitRegisterBtn.disabled = true;
    showMessageToUser(registerHeader, "Wysyłam...", "#007bff");

    const response = await sendForm(url, type, email, phone);

    if (response.status === 200) {
        gtag('event', 'conversion', {'send_to': 'AW-739480194/XEx5CJ340oQDEIKlzuAC'});
        showMessageToUser(registerHeader, "Wiadomość została wysłana.", "#28a745");
    } else {
        showMessageToUser(registerHeader, "Wiadomość nie została wysłana - spróbuj ponownie później.", "#dc3545");
        throw new Error('an error has taken place');
    }
};
// SEND PHONE FORM
const sendPhoneForm = async () => {
    const email = phoneEmail.value;
    const phone = phonePhone.value;
    const url = '/mail/form';
    const type = 'contact';
    if (!phoneForm.checkValidity()) {
        phoneForm.reportValidity();
        return;
    }

    submitPhoneBtn.disabled = true;
    showMessageToUser(phoneFooter, "Wysyłam...", "#007bff");

    const response = await sendForm(url, type, email, phone);

    if (response.status === 200) {
        gtag('event', 'conversion', {'send_to': 'AW-739480194/XEx5CJ340oQDEIKlzuAC'});
        // showMessageToUser(phoneFooter, "Wiadomość została wysłana.", "#28a745");
    } else {
        showMessageToUser(phoneFooter, "Wiadomość nie została wysłana - spróbuj ponownie później.", "#dc3545");
        throw new Error('an error has taken place');
    }
};
// SEND DOWNLOAD APP FORM
const sendDownloadAppForm = async () => {
    const email = downloadAppEmail.value;
    const url = '/mail/download-excel';
    const type = 'download';
    if (!downloadExcelForm.checkValidity()) {
        downloadExcelForm.reportValidity();
        return;
    }

    submitDownloadAppBtn.disabled = true;
    showMessageToUser(downloadAppHeader, "Wysyłam...", "#007bff");

    const response = await sendForm(url, type, email);

    if (response.status === 200) {
        gtag('event', 'conversion', {'send_to': 'AW-739480194/XEx5CJ340oQDEIKlzuAC'});
        showMessageToUser(downloadAppHeader, "Link do Kalkulatora został wysłany na podany adres email.", "#28a745");
    } else {
        showMessageToUser(downloadAppHeader, "Wiadomość nie została wysłana - spróbuj ponownie później.", "#dc3545");
        throw new Error('an error has taken place');
    }
};
// SEND CONTACT FORM
const sendContactForm = async () => {
    const email = contactEmail.value;
    const phone = contactPhone.value;
    const firstName = contactFirstName.value;
    const lastName = contactLastName.value;
    const message = contactMessage.value;
    const url = '/mail/form';
    const type = 'contact';
    if (!contactForm.checkValidity()) {
        contactForm.reportValidity();
        return;
    }

    submitFormBtn.disabled = true;
    showMessageToUser(contactFooter, "Wysyłam...", "#007bff");

    const response = await sendForm(url, type, email, phone, firstName, lastName, message);

    if (response.status === 200) {
        gtag('event', 'conversion', {'send_to': 'AW-739480194/XEx5CJ340oQDEIKlzuAC'});
        showMessageToUser(contactFooter, "Formularz został wysłany.", "#28a745");
        contactForm.reset();
    } else {
        showMessageToUser(contactFooter, "Wiadomość nie została wysłana - spróbuj ponownie później.", "#dc3545");
        throw new Error('an error has taken place');
    }
};


// EVENT LISTENERS
const setSubmitListeners = function() {
    if(submitDownloadBtn)
    submitDownloadBtn.addEventListener('click', (e) => {
        sendDownloadForm();
        e.preventDefault();
    });
    if(submitRegisterBtn)
    submitRegisterBtn.addEventListener('click', (e) => {
        sendRegisterForm();
        e.preventDefault();
    });
    if(submitPhoneBtn)
    submitPhoneBtn.addEventListener('click', (e) => {
        sendPhoneForm();
        e.preventDefault();
    });
    if(submitDownloadAppBtn)
    submitDownloadAppBtn.addEventListener('click', (e) => {
        sendDownloadAppForm();
        e.preventDefault();
    });
    if(submitFormBtn)
    submitFormBtn.addEventListener('click', (e) => {
        sendContactForm();
        e.preventDefault();
    });
}

export {setSubmitListeners};
