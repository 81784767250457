//MODAL WINDOWS
const allModals = document.querySelectorAll('.modal');
const myModal = document.querySelector('#myModal');
const registerLtdModal = document.querySelector('#register-ltd-modal');
const downloadApp = document.querySelector('#downloadApp');
const videoModal = document.querySelector('#video-modal');

// HEADERS to display messages and video
let allHeaders = document.querySelectorAll('.outputMessage');
const videoHeader = document.querySelector('#video-modal .video-header');
let oldModalHeaders = [];

// BUTTONS
// submit buttons
const allSubmitButtons = document.querySelectorAll('form button[type="submit"]')

// open modal windows buttons
const btnMyModal = document.querySelectorAll('a.btn-myModal');
const btnRegisterLtd = document.querySelectorAll('.btn-register-ltd');
const btnDownloadApp = document.querySelectorAll('a[data-target="#downloadApp"].btn-excel');
const playButton = document.querySelector('.play-button');

// CLOSE BUTTON / BACKGROUND
const btnClose = document.querySelectorAll('button.close');
const bgClose = document.querySelectorAll('.modal-bg');

// FUNCTIONS //



// SHOW MESSAGE TO USER
const showMessageToUser = (displayElement, message, color) => {
    displayElement.style.property = 'display: block'
    displayElement.innerHTML = message;
    displayElement.style.color = color;
    setTimeout( ()=> {displayElement.style.property = 'display: none'}, 3000)
}



// RESET MODAL FUNCTION
const resetModal = () => {
    allModals.forEach(item=>{
        item.style.display = "none";
    })
    allSubmitButtons.forEach(item => {
        item.disabled = false;
    })

    allHeaders.forEach(item => {
        item.style.color = '#929292';
    })

    // reset modal headers
    for (let j = 0; j < oldModalHeaders.length; j++) {
        allHeaders[j].innerHTML = oldModalHeaders[j];
    }

    // reset video
    if(videoHeader)
    videoHeader.innerHTML = '';
}

const setModalWindowsReady = () => {
    // ADD CLOSE EVENT LISTENERS TO 'X' and BACKGROUND
if(btnClose)
btnClose.forEach(item => {
    item.addEventListener('click', resetModal)
})
if(bgClose)
bgClose.forEach(item => {
    item.addEventListener('click', resetModal)
})

// EVENT LISTENERS - DISPLAY MODAL WINDOW ON CLICK
// download
if(btnMyModal)
btnMyModal.forEach(item => {
    item.addEventListener('click', () => {
        myModal.style.display = "block";
        downloadForm.reset();
    })
})
// register-ltd-modal
if(btnRegisterLtd)
btnRegisterLtd.forEach(item => {
    item.addEventListener('click', () => {
        registerLtdModal.style.display = "block";
        contactFormLtd.reset();
    })
})
// downloadApp
if(btnDownloadApp)
btnDownloadApp.forEach(item => {
    item.addEventListener('click', () => {
        downloadApp.style.display = "block";
        downloadExcelForm.reset();
    })
})
// PLAY VIDEO
if(playButton)
playButton.addEventListener('click', () => {
    const mq = window.matchMedia( "(min-width: 500px)" );
    if (mq.matches) {
        videoModal.style.display = "block";
        videoHeader.innerHTML = '<iframe width="560" height="315" src="https://www.youtube.com/embed/25aEwtgkvtM?autoplay=1&theme=light" frameborder="0"x allow="accelerometer; encrypted-media; autoplay; gyroscope; picture-in-picture" allowfullscreen></iframe>';
    } else {
        window.open('https://youtu.be/25aEwtgkvtM')
    }
})

// SAVE OLD MODAL HEADERS
const saveOldModalHeaders = () => {
    for (let i = 0; i < allHeaders.length; i++) {
        oldModalHeaders[i] = allHeaders[i].innerHTML;
    }
}
}


export {setModalWindowsReady, showMessageToUser};