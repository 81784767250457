
const toggler = document.querySelector('.toggler');

const showHamburger = document.querySelectorAll('.nav-link').forEach((item) => {
    item.addEventListener('click', (event) => {
        toggler.checked = false;
    });
});

const tagGoogle = function () {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-739480194');
    gtag('config', 'UA-141383601-1');
    var elem = document.createElement('canvas');
    document.documentElement.className = 'no-webp';
    if (!!(elem.getContext && elem.getContext('2d'))) {
            if(elem.toDataURL('image/webp').indexOf('data:image/webp') == 0){
                document.documentElement.className = 'webp';
            }
    }
    // console.log("tagged");
    document.removeEventListener("scroll", tagGoogle);
}

const spyScrolling = function () {
    const scrollSpyElements = document.querySelectorAll('.scrollSpyElement');

    window.onscroll = ( ) => {

      const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

        for (let i = 0; i < scrollSpyElements.length; i++) {
            const section = scrollSpyElements[i];
            const sectionTop = section.offsetTop - 300;
            const sectionHeight = section.offsetTop + section.offsetHeight;
            if (sectionTop <= scrollPos && sectionHeight >= scrollPos) {
                const id = section.id;
                document.querySelector('.current').classList.remove('current');
                document.querySelector(`#main-nav a[href$=${id}]`).classList.add('current');
            }
        }
    }
}


const getCookie = function(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export {showHamburger, tagGoogle, spyScrolling, getCookie}
